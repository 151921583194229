<template>
  <div class="contact">

    <b-alert show variant="info">
      <h4 class="alert-heading">Important Information</h4>
      <p>
        If you wish to set up regular payments into your Cash Box Account from
        your bank account, our details are as follows:
      </p>
      <dl class="row">
        <dt class="col-sm-5 col-md-4">Bank</dt>
        <dd class="col-sm-7 col-md-8">Lloyds plc</dd>
        <dt class="col-sm-5 col-md-4">Account Name</dt>
        <dd class="col-sm-7 col-md-8">Cash Box Credit Union Ltd</dd>
        <dt class="col-sm-5 col-md-4">Sort code</dt>
        <dd class="col-sm-7 col-md-8">30-95-42</dd>
        <dt class="col-sm-5 col-md-4">Account No.</dt>
        <dd class="col-sm-7 col-md-8">80833768</dd>
      </dl>
      <p>
        <strong
        >Please quote your member number &amp; surname on all
          transactions.</strong
        >
      </p>
    </b-alert>

    <b-alert show variant="info" class="mt-3">
      <p>
        If you do need to get in touch please contact us logging into the
        members area or by email
        <a href="mailto:enquiries@cashbox.org.uk">enquiries@cashbox.org.uk</a
        >.
      </p>
      <p>
        Alternatively, you can ring us on the enquiry number below.</p>
    </b-alert>

    <b-row align-v="stretch">
      <b-col md="5">
        <h2>Denton</h2>
        <p>
          Cash Box Credit Union,<br/>Town Hall,<br />Market Street,<br />Denton,<br />M34 2AP
        </p>
        <p>Friday: 10:00am - 1:00pm</p>
        <p>Office:  <a href="tel:01613423266">0161 342 3266</a></p>
        <p>Our phone lines are open 11.00am to 1.00pm on Mondays, Tuesdays, Thursdays and Fridays.</p>
      </b-col>
      <b-col md="7">
        <GoogleMap location="Town Hall, Market Street, Denton, M34 2AP" />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap";
export default {
  name: 'Contact',
  components: {
    GoogleMap
  },
  metaInfo() {
    return {
      title: 'Contact Us | Cash Box Credit Union',
      meta: [
        { name: 'keywords', content: "" },
        { name: 'description', content: "" }
      ],
    };
  }
}
</script>

<style scoped>
.alert p:last-child {
  margin-bottom: 0;
}

</style>
