<template>
  <div class="map pb-3" v-html="map"></div>
</template>

<script>
const mapBaseUrl = "https://www.google.com/maps/embed/v1/place?&key=AIzaSyBGO99WF8-aZsYIdwDJHC70NNbaR-vKnZk&zoom=17&q=";
export default {
  name: "GoogleMap",
  props: {
    location: {
      type: String,
      required: true
    }
  },
  computed: {
    map() {
      return `<iframe src='${mapBaseUrl}${this.location}' width='100%' height='100%' frameborder='0' style='border:0' allowfullscreen></iframe>`;
    }
  }
}
</script>

<style scoped>
.map {
  height: 100%;
}
</style>